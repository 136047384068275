import './App.css';

function App() {
  return (
    <article className="App">
      <header className="App-header">
        <section>
          <h1>Support</h1>
          <p>If you need any support with Nutriverse or have suggestions for the app, feel 
            free to <a className="App-link" href="mailto:mmateusiak@gmail.com">send me an email</a>.</p>
        </section>
        <section>
          <h1>Privacy Policy</h1>
          <h2>I Collect No Personal Information Using My Applications</h2>
          <p>I do not collect, use, save, or share with third parties any of your personal information 
            <em>inserted into Nutriverse</em>. All these data are stored only on your device, and in your private 
            iCloud to facilitate multi-device workflow. Your search queries are used only to retrieve nutrition 
            data from Nutriverse database running on Azure, and Nutriverse does not process it or store it beyond 
            what is necessary for the application's functionality.</p>
            <p>The development of Nutriverse is supported by advertising. Google AdMob is the only ad network used 
            to serve ads across the app. AdMob collects certain user information, such as device model and language. 
            Nutriverse adheres to Google Publisher Policies described 
            <a href="https://support.google.com/admob/answer/10502938">here</a>.</p>
            <p>Nutriverse may also use Google Analytics for Firebase to better understand its users and improve the 
            product. You can learn more about how your data is securely processed by Firebase 
            <a href="https://firebase.google.com/support/privacy">here</a></p>.
        </section>
        <section>
          <h1>Disclaimer</h1>
          <p>All information in Nutriverse is for reference only and should not be used to 
            make critical health-related decisions. Use at your own discretion.</p>
        </section>
      </header>
      <footer>
        <span>Copyright © 2023 Michał Mateusiak. All rights reserved.</span>
      </footer>
    </article>
  );
}

export default App;
